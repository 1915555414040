import usePreviewMode from 'hooks/usePreviewMode';
import FlightServiceCardTheme4 from '../Theme4Card';
import DragAndDropContainer from 'modules/WebBuilder-V2/shared/DragAndDropContainer';
import { SORT_ITEMS_IN_CONTAINER_ACTION } from 'modules/WebBuilder-V2/interfaces/blockTypes';
import { EditBox } from 'modules/WebBuilder-V2/shared/EditBox';
import EditableParagraph from 'modules/WebBuilder-V2/shared/EditableParagraph';
import { AddElement } from 'modules/WebBuilder-V2/shared/AddElement';
import Locale from 'translations';
import { useWebBuilderState } from 'context/webBuilder';
import UploadFileComponent from 'modules/WebBuilder-V2/shared/UploadFile';
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";

const flightBlocksType = "flightBlocksType";


export default function ThemeSevenFlights({
  items,
  sortFlightsItems,
  focusContainer,
  type,
  editItem,
  itemsBoxActions,
  updateFlights,
  title,
  body,
  handleOpenAddModal,
  image,
}) {
  const { webBuilder } = Locale;
  const { isPreview } = usePreviewMode();
  const { selectedLanguage } = useWebBuilderState();

  const logoImage = image ? <img src={image} alt="Logo" /> : null;
  const logo = isPreview ? (
    logoImage
  ) : (
    <UploadFileComponent
      icon={AddIcon}
      id={`${type}-section`}
      label=""
      onUpload={(value) => updateFlights({ name: "image", value, language: null })}
      image={<span className="d-block h-100">{logoImage} {<AddIcon />} </span>}
    />
  );

  
  
  return (
    <div className={`theme_flight_section section_padding__lg ${isPreview ? '' : 'edit_mode'}`} id="flight-section">
      <div className="content_col">
        <div className="heading mb-4">

          <EditableParagraph
            className="title"
            contentEditable={true}
            onInput={(value) => {
              updateFlights({ name: "title", value, language: selectedLanguage })
            }}
            text={title}

          />

          <EditableParagraph
            className="heading_text"
            contentEditable={true}
            onInput={(value) => {
              updateFlights({ name: "body", value, language: selectedLanguage })
            }}
            text={body}

          />

          <AddElement
            onClick={handleOpenAddModal}
            title={webBuilder.addMoreFlights}
          />

          {/* <h4 className="title">Flights Offers</h4>
          <p className="heading_text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
          </p> */}
        </div>
        <div className="content">
          {items.map((item, index) => (
            <DragAndDropContainer
              accept={flightBlocksType}
              type={flightBlocksType}
              id={item.id}
              index={index}
              action={SORT_ITEMS_IN_CONTAINER_ACTION}
              moveItemInContainer={(dragIndex, hoverIndex) =>
                sortFlightsItems({ dragIndex, hoverIndex })
              }
              key={item.id}
            >
              <EditBox
                editMode={focusContainer === type}
                actions={[
                  ...editItem(item, index),
                  ...itemsBoxActions(item.id),
                ]}
              >
                <FlightServiceCardTheme4 {...item} itemType="flight" />
              </EditBox>
            </DragAndDropContainer>
          ))}
        </div>
      </div>


      <div className="bg_col">
        {logo}
        {/* <img src=""
          alt="Safa Visa" /> */}
      </div>
    </div>
  )
}