import { ReactComponent as TrashIcon } from "assets/images/hotelAccommodation/trash.svg";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import { SORT_ITEMS_IN_CONTAINER_ACTION } from "modules/WebBuilder/interfaces/blockTypes";
import { AddElement } from "modules/WebBuilder-V2/shared/AddElement";
import AddService from "modules/WebBuilder-V2/shared/AddService";
import DragAndDropContainer from "modules/WebBuilder-V2/shared/DragAndDropContainer";
import { EditBox } from "modules/WebBuilder-V2/shared/EditBox";
import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";
import { useCallback, useState } from "react";
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting-224.svg";
import PackageCard from "./PackageCard";
import Carousel from "react-multi-carousel";
import { ReactComponent as RightICon } from "assets/images/webBuilder/right.svg";
import { ReactComponent as LeftIcon } from "assets/images/webBuilder/left.svg";
import OurPackagesWithImage from "./PackagesWithImage";
import Locale from "translations";
import ThemeSevenPackages from "./theme7";
import OurPackagesThemeFour from './theme4';

const packagesBlocksType = "packagesBlocksType";

function OurPackages({ details, onFocus, focusContainer }) {
	const { webBuilder } = Locale;

	const { selectedLanguage, style } = useWebBuilderState();
	let { id, title, body, items, hasSlider } = details;

	const [openAdd, setOpenAdd] = useState(false);
	const [updateItem, setUpdateItem] = useState(null);
	const dispatch = useWebBuilderDispatch();

	items  = items?.filter((i) => i !== null && i !== undefined);

	const handleOpenAddModal = useCallback(() => {
		setOpenAdd((prev) => !prev);
		setUpdateItem(null);
	}, []);

	const openEditModal = (item) => {
		handleOpenAddModal();
		setUpdateItem(item);
	};

	const editItem = (item, index) => {
		return [
			<span
				key={`${item?.id}-${index}`}
				onClick={() => openEditModal({ ...item, index })}
				className="pointer-event"
			>
				<SettingIcon />
			</span>,
		];
	};

	const addItemToPackages = useCallback(
		(item) => {
			updateItem
				? dispatch({
						type: "editItemInContainer",
						payload: {
							id,
							item,
							itemIndex: updateItem.index,
						},
				  })
				: dispatch({
						type: "addItemToContainer",
						payload: {
							id,
							item,
						},
				  });
			setUpdateItem(null);
		},
		[dispatch, id, updateItem]
	);

	const deleteBodyContent = ({ itemId, deleteType }) => {
		dispatch({
			type: "deleteBodyContent",
			payload: {
				id,
				deleteType,
				itemId,
			},
		});
	};

	const sortPackagesItems = ({ dragIndex, hoverIndex }) => {
		dispatch({
			type: "sortItemsInContainer",
			payload: {
				id,
				dragIndex,
				hoverIndex,
			},
		});
	};
	const updatePackages = ({ name, value, language }) => {
		dispatch({
			type: "updateBodyContent",
			payload: {
				name,
				value,
				id,
				language,
			},
		});
	};
	const itemsBoxActions = (itemId) => {
		return [
			<span
				key={itemId}
				onClick={() =>
					deleteBodyContent({ itemId, deleteType: "container_item" })
				}
				className="pointer-event"
			>
				<TrashIcon />
			</span>,
		];
	};

	const containerBoxActions = [
		// <span
		// 	onClick={() => deleteBodyContent({ deleteType: "container" })}
		// 	className="pointer-event"
		// >
		// 	<TrashIcon />
		// </span>,
	];

	// const selectedIds = useMemo(
	// 	() => (items ? items.map((i) => i.id) : []),
	// 	[items]
	// );
	const selectedIds = items ? items.map((i) => i?.id) : [];

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 4,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 4,
		},
		tablet: {
			breakpoint: { max: 1024, min: 567 },
			items: 2,
		},
		mobile: {
			breakpoint: { max: 567, min: 0 },
			items: 1,
		},
	};

	const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
		return (
			<div className="carousel-button-group">
				<CustomLeftArrow onClick={() => previous()} />
				<CustomRightArrow onClick={() => next()} />
			</div>
		);
	};

	const CustomRightArrow = ({ onClick }) => (
		<button
			className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
			onClick={onClick}
		>
			<RightICon />
		</button>
	);

	const CustomLeftArrow = ({ onClick }) => (
		<button
			className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
			onClick={onClick}
		>
			<LeftIcon className="r-left-icon" />
		</button>
	);
	return (
		<>
			{hasSlider ? (
				<EditBox editMode={focusContainer === id} actions={containerBoxActions}>
					<div
						className="web-builder-content-our-packages-with-slider container-fluid builder_container_fluid"
						id="packages-section"
						onClick={() => onFocus(id)}
					>
						<div className="our-packages-header">
							<ServicesContent
								header={title}
								body={body}
								changeHeader={(value) =>
									updatePackages({
										name: "title",
										value,
										language: selectedLanguage,
									})
								}
								changebody={(value) =>
									updatePackages({
										name: "body",
										value,
										language: selectedLanguage,
									})
								}
							/>
							<button className="btn ">{webBuilder.viewAllPackages}</button>
						</div>

						<AddElement
							onClick={handleOpenAddModal}
							title={webBuilder.addMorePackages}
							className="add-element-box-packages"
						/>
						{items.length > 0 && (
							<div className="all-web-builder-our-service-carousel2 position-relative">
								<Carousel
									responsive={responsive}
									itemClass={"service-slide"}
									className="web-builder-our-service-carousel"
									slidesToSlide={1}
									keyBoardControl={true}
									// customRightArrow={<CustomRightArrow />}
									// customLeftArrow={<CustomLeftArrow />}
									customButtonGroup={<ButtonGroup />}
									renderButtonGroupOutside={true}
									removeArrowOnDeviceType={["tablet", "mobile"]}
									infinite={true}
									arrows={false}
								>
									{items.map((props, index) => (
										<DragAndDropContainer
											accept={packagesBlocksType}
											type={packagesBlocksType}
											id={props?.id}
											index={index}
											action={SORT_ITEMS_IN_CONTAINER_ACTION}
											moveItemInContainer={(dragIndex, hoverIndex) =>
												sortPackagesItems({ dragIndex, hoverIndex })
											}
											key={props?.id}
										>
											<EditBox
												editMode={focusContainer === id}
												actions={[
													...editItem(props, index),
													...itemsBoxActions(props?.id),
												]}
											>
												<PackageCard
													{...props}
													key={index}
													openEditModal={openEditModal}
												/>
											</EditBox>
										</DragAndDropContainer>
									))}
								</Carousel>

								<button className="btn btn-mobile ">
									{webBuilder.viewAllPackages}
								</button>
							</div>
						)}
						{/* <div className="my-2 our-packages-detials">
				</div> */}
						{openAdd && (
							<AddService
								handleModalState={handleOpenAddModal}
								open={openAdd}
								label={webBuilder.packages}
								header={webBuilder.package}
								onAdd={addItemToPackages}
								details={updateItem}
								selectedIds={selectedIds}
							/>
						)}
					</div>
				</EditBox>
			) : style.theme === "theme-4" ? (
				<OurPackagesThemeFour
					details={details}
					onFocus={onFocus}
					focusContainer={focusContainer}
				/>
			) : style.theme === "theme-7" ? (
				<ThemeSevenPackages
					details={details}
					onFocus={onFocus}
					focusContainer={focusContainer}
				/>
			) : (
				<OurPackagesWithImage
					details={details}
					onFocus={onFocus}
					focusContainer={focusContainer}
				/>
			)}
		</>
	);
}

export default OurPackages;
