import { useWebBuilderState } from 'context/webBuilder';
import EditableParagraph from 'modules/WebBuilder-V2/shared/EditableParagraph';
import { EditBox } from 'modules/WebBuilder-V2/shared/EditBox';
import { useState } from 'react';
import { Collapse } from 'reactstrap';
import { ReactComponent as PlusIcon } from "assets/images/webBuilder/plus.svg";
import { ReactComponent as MinusIcon } from "assets/images/webBuilder/minus.svg";

export default function FAQTheme7({
  title,
  handleFaqs,
  faqs,
  image,
  focusContainer,
  id,
  itemsBoxActions
}) {
  const { selectedLanguage } = useWebBuilderState();
  const [collapsedItems, setCollapsedItems] = useState([]);
  return (
    <>
      <div className="container">
        <section className="theme_faq_section" style={{ backgroundImage: `url(${image})` }}>
          <div className="col-xl-6 col-lg-7 col-md-8">
            <div className="theme_faq_card">
              <EditableParagraph
                className="mb-3 font-weight-bold h4"
                contentEditable={true}
                onInput={(value) =>
                  handleFaqs({
                    value,
                    name: "title",
                    language: selectedLanguage,
                  })
                }
                text={title}
              />

              {/* <h3 className="mb-3 font-weight-bold h4">{title}</h3> */}
              {faqs &&
                faqs?.map((que, index) => {
                  const isOpen = collapsedItems.includes(index);
                  const openCollapse = () => {
                    const updated = [...collapsedItems, index];
                    setCollapsedItems(updated);
                  };
                  const closeCollapse = () =>
                    setCollapsedItems(
                      collapsedItems.filter((i) => i !== index)
                    );
                  return (
                    <div className="faqs-question-wrapper" key={que.id}>
                      <EditBox
                        editMode={focusContainer === id}
                        actions={[itemsBoxActions(que.id)]}
                        defaultIcon=""
                        className="mb-2"
                      >
                        <div className="faqs-container-question">
                          <span> {que.question[selectedLanguage]} </span>
                          {isOpen ? (
                            <button
                              className="bg-transparent border-0 faq-btn"
                              onClick={closeCollapse}
                            >
                              <MinusIcon />
                            </button>
                          ) : (
                            <button
                              className="bg-transparent border-0 faq-btn"
                              onClick={openCollapse}
                            >
                              <PlusIcon />
                            </button>
                          )}
                        </div>
                        <Collapse isOpen={isOpen}>
                          <EditableParagraph
                            className="faqs-container-body"
                            contentEditable={true}
                            onInput={(value) =>
                              handleFaqs({
                                value,
                                name: "body",
                                questionIndex: index,
                                language: selectedLanguage,
                              })
                            }
                            text={que.body[selectedLanguage]}
                          />
                        </Collapse>
                      </EditBox>
                    </div>
                  )
                })}
            </div>
          </div>

        </section>
      </div>
    </>
  )
}