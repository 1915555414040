import { AddElement } from 'modules/WebBuilder-V2/shared/AddElement';
import AddService from 'modules/WebBuilder-V2/shared/AddService';
import React, { useState } from 'react'
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting-224.svg";
import { ReactComponent as TrashIcon } from "assets/images/hotelAccommodation/trash.svg";
import { useWebBuilderDispatch, useWebBuilderState } from 'context/webBuilder';
import HotelGridView from 'modules/WebBuilder-V2/Components/Content/HotelService/HotelGridView';
import HotelCarouselView from 'modules/WebBuilder-V2/Components/Content/HotelService/HotelCarouselView';
import { EditBox } from 'modules/WebBuilder-V2/shared/EditBox';
import HotelTheme3 from 'modules/WebBuilder-V2/Components/Content/HotelService/HotelTheme3';
import Locale from 'translations';

export default function CustomPageHotels({ details, pageId, focusContainer, onFocus, title, preview }) {
  const { webBuilder } = Locale;
  const { style } = useWebBuilderState();
  const { id, type, items, hotel_type, index } = details;
  

  const [openAdd, setOpenAdd] = useState(false);
  const [updateItem, setUpdateItem] = useState(null);

  const dispatch = useWebBuilderDispatch();


  function handleOpenAddModal() {
    setOpenAdd((prev) => !prev);
  };
  function openEditModal(item) {
    handleOpenAddModal();
    setUpdateItem(item);
  };

  function editItem(item, index) {
    return [
      <span className="pointer-event" key={`${item.id}-${index}`}
        onClick={() => openEditModal({ ...item, index })}
      >
        <SettingIcon />
      </span>,
    ];
  };

  function deleteBodyContent(itemId) {
    dispatch({
      type: "deleteItemFromCustomPage",
      payload: {
        sectionId: id,
        itemId,
        pageId
      },
    });
  };
  function itemsBoxActions(itemId) {
    return [
      <span className="pointer-event" key={itemId}
        onClick={() => deleteBodyContent(itemId)}
      >
        <TrashIcon />
      </span>
    ];
  };

  function addItemToHotels(item) {
    updateItem
      ? dispatch({
        type: "editItemToCustomPage",
        payload: {
          sectionIndex: index,
          item,
          itemIndex: updateItem.index,
          pageId
        },
      })
      : dispatch({
        type: "addItemToCustomPage",
        payload: {
          sectionIndex: index,
          item,
          pageId
        },
      });
    setUpdateItem(null);
  };

  function sortHotelsItems({ dragIndex, hoverIndex }) {
    // dispatch({
    //   type: "sortItemsInContainer",
    //   payload: {
    //     sectionId: id,
    //     dragIndex,
    //     hoverIndex,
    //   },
    // });
  };

  let hotelViewProps = {
    items,
    editItem,
    focusContainer,
    itemsBoxActions,
    openEditModal,
    sortHotelsItems,
    type,
  }

  function renderHotelView() {
    switch (style.theme || hotel_type) {
      case "theme-1":
        return <HotelGridView {...hotelViewProps} />
      case "theme-2":
        return <HotelCarouselView {...hotelViewProps} />
      case "theme-3":
        return <HotelTheme3 {...hotelViewProps} />
      default:
        return <HotelCarouselView {...hotelViewProps} />
    }
  }
  const selectedIds = items ? items.map((i) => i.id) : [];


  return (
    <>
      {(items?.length > 0 || !preview) &&
        <h3 className="section-title">
          {title}
        </h3>
      }
      <EditBox editMode={focusContainer === type} defaultIcon={""}>
        <div id={id} className="web-builder-content-hotels" onClick={() => onFocus(type)}>
          {!preview &&
            <div className="service-top-section">
              <AddElement title="Add Hotels" onClick={handleOpenAddModal} />
            </div>
          }


          <div className="hotels-detials services-carousel-container">
            {items && renderHotelView()}
          </div>

          {openAdd && <AddService
            handleModalState={handleOpenAddModal}
            open={openAdd}
            item_id={6}
            label={webBuilder.hotels}
            header={webBuilder.hotel}
            onAdd={addItemToHotels}
            details={updateItem}
            selectedIds={selectedIds}
          />}
        </div>
      </EditBox>
    </>
  )
}
