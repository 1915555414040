import { useWebBuilderState } from 'context/webBuilder'
import React from 'react'
import { ReactComponent as FacebookIcon } from 'assets/images/webBuilder/Facebook.svg'
import { ReactComponent as XIcon } from 'assets/images/webBuilder/XIcon.svg'
import { ReactComponent as YouTubeIcon } from 'assets/images/webBuilder/youtube.svg'
import { ReactComponent as LinkedInIcon } from 'assets/images/webBuilder/linkedin.svg'
import { ReactComponent as InstagramIcon } from 'assets/images/webBuilder/instagram.svg'
import { ReactComponent as SnapchatIcon } from 'assets/images/webBuilder/snapchat-icon.svg'
import { ReactComponent as TiktokIcon } from 'assets/images/webBuilder/tiktok-icon.svg'
import FooterThemeOne from './Theme-1'
import FooterThemeTwo from './Theme-2'
import FooterThemeSeven from './Theme-7'
import FooterThemeFour from './Theme-4'



export default function Footer({ focusContainer, handleFocus }) {
	const { style } = useWebBuilderState()


	const socialMediaIcons = {
		facebook: <FacebookIcon />,
		twitter: <XIcon />,
		youtube: <YouTubeIcon />,
		instagram: <InstagramIcon />,
		linkedin: <LinkedInIcon />,
		snapchat: <SnapchatIcon />,
		tiktok: <TiktokIcon />
	}

	return (
		<footer className='footer-container'>

			{style?.theme === 'theme-1' && (
				<FooterThemeOne
					focusContainer={focusContainer}
					handleFocus={handleFocus}
					socialMediaIcons={socialMediaIcons}
				/>
			)}
			
			{style?.theme === 'theme-2' && (
				<FooterThemeTwo
					focusContainer={focusContainer}
					handleFocus={handleFocus}
					socialMediaIcons={socialMediaIcons}
				/>
			)}

			{style?.theme === 'theme-3' && (
				<FooterThemeOne
					focusContainer={focusContainer}
					handleFocus={handleFocus}
					socialMediaIcons={socialMediaIcons}
				/>
			)}

			{style?.theme === 'theme-4' && (
				<FooterThemeFour
					focusContainer={focusContainer}
					handleFocus={handleFocus}
					socialMediaIcons={socialMediaIcons}
				/>
			)}

			{style?.theme === 'theme-7' && (
				<FooterThemeSeven
					focusContainer={focusContainer}
					handleFocus={handleFocus}
				/>
			)}
			
		</footer>
	)
}
