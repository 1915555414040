import React, { useEffect, useRef, useState } from 'react'
import newWallat from "assets/images/newWallat.svg";
import noHotel from "assets/images/placeHoleder.png";
import { Link , useParams} from 'react-router-dom';
import { useSBSState } from 'context/global';
import Locale from "translations";

export default function ListItemMap({ item, searchBy, Night, itemKey }) {
  const { marketPlace } = Locale;
  const {status} = useParams()
  const { token_data } = useSBSState();
  const imageRef = useRef(null);
  const search = window.location.search;
  const name = new URLSearchParams(search);
  const [params, setParams] = useState({});

  useEffect(() => {
    let x = {};
    for (const [key, value] of name) {
      x = { ...x, [key]: value };
    }
    setParams(x);
  }, []);
  return (
    <div className="hotel-map-detail-section">
        <div className="img-box">
          <img
            src={item.image ? `${item.image}` : noHotel}
            ref={imageRef}
            onError={(e) => {
              imageRef.current.src = noHotel;
            }}
            alt={item.name}
            className="img-fluid"
          />
        </div>
        <div className="hotel-map-detail-content">
          <h2 className='hotel-map-detail-title'>{item.name}</h2>
          <span className='hotel-map-detail-address'>
            {item.address !== "" ? item.address + " |" : item.address}
          </span>
          <div className="m-0">
            {[...Array(+item.rating).keys()].map((i) => (
              <i key={i} className="fa fa-star" aria-hidden="true"></i>
            ))}
          </div>
        </div>


        <div className="hotel-map-price">
          {item.cachedHotels.map((res, i) => {
            return (
              <Link
                onClick={() =>
                  localStorage.setItem(
                    "selectedHotelSearch",
                    JSON.stringify({ ...item, search_token_selected: res.search_token })
                  )
                }
                to={{
                  pathname: params.hasOwnProperty("token")
                    ? `/market-details/${res.search_token}?token=${params.token}`
                    : `/${status}/market-details/${res.search_token}`,
                  state: {
                    code: res.hotel_code,
                  },
                }}
                key={i}
              >
                <div className="total-price-box">
                  {token_data && token_data.discount != null ?
                    <div className="d-flex justify-content-between">
                      <p
                        className="d-flex align-items-center px-1 "
                        style={{ color: "red",minWidth:"75px" }}
                      >
                        <span className="font-weight-bold">
                          {token_data.discount}
                        </span>{" "}
                        %{" OFF"}
                      </p>

                      <div className="d-flex pr-3">
                        <img
                          src={newWallat}
                          className="px-1"
                          alt=""
                          srcSet=""
                        />
                        <p
                          className="price-number price-text"
                          style={{
                            fontWeight: "700",
                            lineHeight: ".9",
                            fontSize: "17px",
                            marginBottom: "5px",
                            marginTop: "5px",
                          }}
                        >
                          <p className="old-price">
                            {" "}
                            {(
                              (res.total_price /
                                Math.ceil(100 - token_data.discount)) *
                              token_data.discount +
                              res.total_price
                            )}{" "}
                            {res.currency}
                          </p>
                          {res.total_price}{" "}
                          <span className="text-currency pr-1">
                            {res.currency}
                          </span>
                          <span className="text-Nights">
                            /{Night} {marketPlace.Nights} {" "}
                          </span>
                        </p>
                      </div>
                    </div>
                    :
                    <div className="d-flex justify-content-end">
                      <img
                        src={newWallat}
                        className="px-2"
                        alt=""
                        srcSet=""
                      />
                      <div>
                        <h6 className="new-color">
                          {marketPlace.messages.startFrom}
                        </h6>

                        <p
                          className="price-number price-text"
                          style={{
                            fontWeight: "700",
                            lineHeight: ".9",
                            fontSize: "18px",
                            marginBottom: "5px",
                          }}
                        >
                          {res.total_price}{" "}
                          <span className="text-currency pr-1">
                            {res.currency}
                          </span>
                          <span className="text-Nights">
                            /{Night} {marketPlace.Nights} {" "}
                          </span>
                        </p>
                      </div>
                    </div>
                  }
                </div>
              </Link>
            )
          })}
        </div>
      </div>

  )
}
