import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";
import React, { useEffect, useState } from "react";
import { AddElement } from "modules/WebBuilder-V2/shared/AddElement";
import { EditBox } from "modules/WebBuilder-V2/shared/EditBox";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import { ReactComponent as TrashIcon } from "assets/images/hotelAccommodation/trash.svg";
import AddService from "modules/WebBuilder-V2/shared/AddService";
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting-224.svg";
import HotelGridView from './HotelGridView';
import HotelCarouselView from './HotelCarouselView';
import Locale from 'translations';
import HotelTheme3 from './HotelTheme3';
import HotelTheme4 from './HotelTheme4';
import HotelTheme7 from './Theme7';



function HotelService({ details, onFocus, focusContainer }) {
	const { webBuilder } = Locale;
	const { selectedLanguage, style } = useWebBuilderState();
	let { id, type, title, body, items, hotel_type, preview } = details;
	const [openAdd, setOpenAdd] = useState(false);
	const [updateItem, setUpdateItem] = useState(null);
	const [realHotelsCount, setRealHotelsCount] = useState(0);
	const dispatch = useWebBuilderDispatch();


	items  = items?.filter((i) => i !== null && i !== undefined);

	const handleOpenAddModal = () => {
		setOpenAdd((prev) => !prev);
		setUpdateItem(null)
	};

	const openEditModal = (item) => {
		handleOpenAddModal();
		setUpdateItem(item);
	};

	const editItem = (item, index) => {
		return [
			<span
				key={`${item?.id}-${index}`}
				onClick={() => openEditModal({ ...item, index })}
				className="pointer-event"
			>
				<SettingIcon />
			</span>,
		];
	};

	const addItemToHotels = (item) => {
		updateItem
			? dispatch({
				type: "editItemInContainer",
				payload: {
					id,
					item,
					itemIndex: updateItem.index,
				},
			})
			: dispatch({
				type: "addItemToContainer",
				payload: {
					id,
					item,
				},
			});
		setUpdateItem(null);
	};

	const updateHotel = ({ name, value, language }) => {
		dispatch({
			type: "updateBodyContent",
			payload: {
				name,
				value,
				id,
				language
			},
		});
	};

	const sortHotelsItems = ({ dragIndex, hoverIndex }) => {
		dispatch({
			type: "sortItemsInContainer",
			payload: {
				id,
				dragIndex,
				hoverIndex,
			},
		});
	};

	const deleteBodyContent = ({ itemId, deleteType }) => {
		dispatch({
			type: "deleteBodyContent",
			payload: {
				id,
				deleteType,
				itemId,
			},
		});
	};

	const itemsBoxActions = (itemId) => {
		return [
			<span
				key={itemId}
				onClick={() =>
					deleteBodyContent({ itemId, deleteType: "container_item" })
				}
				className="pointer-event"
			>
				<TrashIcon />
			</span>,
		];
	};

	const hotelsProps = {
		items,
		editItem,
		focusContainer,
		itemsBoxActions,
		openEditModal,
		sortHotelsItems,
		type
	}

	function renderHotelView() {
		return {
			"grid": <HotelGridView {...hotelsProps} />,
			"carousel": <HotelCarouselView {...hotelsProps} />,
			"theme-3": <HotelTheme3 {...hotelsProps} />,
			"theme-4": <HotelTheme4 {...hotelsProps} />,
			"theme-7": <HotelTheme7 {...hotelsProps} title={title} body={body} handleOpenAddModal={handleOpenAddModal} updateHotel={updateHotel} />,
		}
	}
	const selectedIds = items ? items.map((i) => i?.id) : [];

	useEffect(() => {
		setRealHotelsCount(items?.filter(item => item.product_uuid)?.length || 0)
	}, [preview])

	return (
		<EditBox editMode={focusContainer === type}>
			{
				((preview && realHotelsCount) || !preview) && (
					<div
						className="web-builder-content-hotels container"
						onClick={() => onFocus(type)}
						id={id}
					>
						{style.theme !== "theme-7" &&
							<div className="service-top-section">
								<ServicesContent
									header={title}
									body={body}
									changeHeader={(value) => updateHotel({ name: "title", value, language: selectedLanguage })}
									changebody={(value) => updateHotel({ name: "body", value, language: selectedLanguage })}
								/>
								<AddElement
									onClick={handleOpenAddModal}
									title={webBuilder.addMoreHotels}
								/>
							</div>
						}

						<div className="hotels-detials services-carousel-container">
							{items && renderHotelView()?.[hotel_type]}

						</div>
						{openAdd && <AddService
							handleModalState={handleOpenAddModal}
							open={openAdd}
							item_id={6}
							label={webBuilder.hotels}
							header={webBuilder.hotel}
							onAdd={addItemToHotels}
							details={updateItem}
							selectedIds={selectedIds}
						/>}
					</div>
				)
			}
		</EditBox>
	);
}

export default HotelService;
