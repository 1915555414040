import ServicesContent from "modules/WebBuilder/shared/ServicesContent";
import React, { useState } from "react";
import { AddElement } from "modules/WebBuilder-V2/shared/AddElement";
// import { ServiceCardDetails } from "modules/WebBuilder/shared/ServiceCardDetails";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";

import { SORT_ITEMS_IN_CONTAINER_ACTION } from "../../../interfaces/blockTypes";
import { EditBox } from "modules/WebBuilder/shared/EditBox";
import DragAndDropContainer from "modules/WebBuilder/shared/DragAndDropContainer";
import AddService from "modules/WebBuilder-V2/shared/AddService";
import FlightServiceCard from "./FlightCard";
import { ReactComponent as TrashIcon } from "assets/images/hotelAccommodation/trash.svg";
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting-224.svg";
import Locale from "translations";
import FlightServiceCardTheme4 from "./Theme4Card";
import ThemeSevenFlights from './theme7';

const flightBlocksType = "flightBlocksType";


function FlightsService({ details, onFocus, focusContainer }) {
	const { id, type, body, title, items, items_per_row, image } = details;
	const { selectedLanguage, style } = useWebBuilderState();
	const dispatch = useWebBuilderDispatch();
	const [openAdd, setOpenAdd] = useState(false);
	const [updateItem, setUpdateItem] = useState(null);
	const { webBuilder } = Locale;

	const handleOpenAddModal = () => {
		setOpenAdd((prev) => !prev);
		setUpdateItem(null);
	};
	const openEditModal = (item) => {
		handleOpenAddModal();
		setUpdateItem(item);
	};

	const editItem = (item, index) => {
		return [
			<span
				key={`${item.id}-${index}`}
				onClick={() => openEditModal({ ...item, index })}
				className="pointer-event"
			>
				<SettingIcon />
			</span>,
		];
	};

	const addItemToFlight = (item) => {
		updateItem
			? dispatch({
				type: "editItemInContainer",
				payload: {
					id,
					item,
					itemIndex: updateItem.index,
				},
			})
			: dispatch({
				type: "addItemToContainer",
				payload: {
					id,
					item,
				},
			});
		setUpdateItem(null);
	};

	const updateFlights = ({ name, value, language }) => {
		dispatch({
			type: "updateBodyContent",
			payload: {
				name,
				value,
				id,
				language
			},
		});
	};

	const sortFlightsItems = ({ dragIndex, hoverIndex }) => {
		dispatch({
			type: "sortItemsInContainer",
			payload: {
				id,
				dragIndex,
				hoverIndex,
			},
		});
	};

	const deleteBodyContent = ({ itemId, deleteType }) => {
		dispatch({
			type: "deleteBodyContent",
			payload: {
				id,
				deleteType,
				itemId,
			},
		});
	};
	const itemsBoxActions = (itemId) => {
		return [
			<span
				key={itemId}
				onClick={() =>
					deleteBodyContent({ itemId, deleteType: "container_item" })
				}
				className="pointer-event"
			>
				<TrashIcon />
			</span>,
		];
	};
	const selectedIds = items ? items.map((i) => i.id) : [];


	return (
		<EditBox editMode={focusContainer === type}>
			<div
				onClick={() => onFocus(type)}
				className={`web-builder-content-flights ${style.theme === "theme-7" ? '' : 'container'}`}
				id={id}
			>
				{style.theme === "theme-7" ?
					<ThemeSevenFlights
						items={items}
						sortFlightsItems={sortFlightsItems}
						focusContainer={focusContainer}
						type={type}
						editItem={editItem}
						itemsBoxActions={itemsBoxActions}
						updateFlights={updateFlights}
						title={title}
						body={body}
						handleOpenAddModal={handleOpenAddModal}
						image={image}
					/>
					:
					<>
						<div className="service-top-section">
							<ServicesContent
								header={title}
								body={body}
								changeHeader={(value) =>
									updateFlights({
										name: "title",
										value,
										language: selectedLanguage,
									})
								}
								changebody={(value) =>
									updateFlights({ name: "body", value, language: selectedLanguage })
								}
							/>
							<AddElement
								onClick={handleOpenAddModal}
								title={webBuilder.addMoreFlights}
							/>
						</div>

						<div
							className="flights-cards-container"
							style={{ gridTemplateColumns: `repeat(${+items_per_row} , 1fr)` }}
						>
							{items.map((item, index) => (
								<DragAndDropContainer
									accept={flightBlocksType}
									type={flightBlocksType}
									id={item.id}
									index={index}
									action={SORT_ITEMS_IN_CONTAINER_ACTION}
									moveItemInContainer={(dragIndex, hoverIndex) =>
										sortFlightsItems({ dragIndex, hoverIndex })
									}
									key={item.id}
								>
									<EditBox
										editMode={focusContainer === type}
										actions={[
											...editItem(item, index),
											...itemsBoxActions(item.id),
										]}
									>
										{style.theme === "theme-4" ? (
											<FlightServiceCardTheme4 {...item} itemType="flight" />
										) : (
											<FlightServiceCard {...item} itemType="flight" />
										)}
									</EditBox>
								</DragAndDropContainer>
							))}
						</div>
					</>
				}

				{openAdd && (
					<AddService
						handleModalState={handleOpenAddModal}
						open={openAdd}
						item_id={1}
						label={webBuilder.flight}
						header={webBuilder.flights}
						onAdd={addItemToFlight}
						details={updateItem}
						selectedIds={selectedIds}
					/>
				)}
			</div>
		</EditBox>
	);
}

export default FlightsService;
